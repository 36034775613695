import React, { useState } from 'react'
import { PageProps } from 'gatsby'
//import { useMediaQuery } from 'react-responsive'
import {
  Head,
  //Video,
  Carousel,
  Modal,
  Footer,
  LazyLoad,
} from '~/components'
import { useApi } from '~/siteApi'
import cn from 'classnames'
import * as st from '~/assets/styl/Company.module.styl'
import Logo from '~/assets/svg/Logo-without-type.svg'
//import Thumbnail from '~/assets/img/Thumbnail.jpg'

function numberIcon(index: number) {
  switch (index) {
    case 0:
      return 'icon-numbers-flag'
    case 1:
      return 'icon-numbers-fence'
    default:
      return 'icon-numbers-pin'
  }
}

function getVideoId(url: string) {
  const urlParts = url.split(/(youtu\.be\/)|(youtube\.com\/watch\?v=)/)
  return urlParts[urlParts.length - 1]
}

type Context = {
  numbers: Array<{
    name: string
    number: string
  }>
  campaigns: Array<{
    name: string
    url: string
  }>
  attendants: Array<{
    name: string
    items: Array<string>
  }>
}

const Company = ({ location, pageContext }: PageProps) => {
  const { numbers, campaigns /* attendants*/ }: Context = useApi(
    pageContext,
    'page-about'
  )
  const [videoUrl, setVideoUrl] = useState<string>(null)
  const [manifestOpen, setManifestOpen] = useState(false)

  return (
    <>
      <Head location={location} title="Sobre a Alínea Urbanismo" />

      <header className={st.header}>
        <div className="container">
          <h1>Alínea Urbanismo</h1>
          <h2>Construímos espaços que fazem histórias</h2>
          <p className={cn(manifestOpen && st.open)}>
            <strong>Manifesto Alínea</strong>
            <br />
            <br />
            O que faz diferença em nossas vidas é a forma como a observamos.
            <br />
            E aqui, observamos tudo sob a perspectiva Alínea, que valoriza o
            bem-estar como nosso bem mais valioso.
            <br />
            E quando falamos em bem-estar, o exploramos em todos os seus
            âmbitos, honrando nosso compromisso com as pessoas, o meio ambiente,
            a tecnologia e, principalmente, a cultura de nossas relações.
            <br />
            Somos reconhecidos por nossa idoneidade no mercado, pela preocupação
            com o meio ambiente e pela realização de projetos e ações
            sustentáveis que beneficiam a comunidade.
            <br />
            Afinal, estamos presentes em mais de 20 cidades, com mais de 15 mil
            terrenos desenvolvidos, milhares de árvores plantadas e mais de 15
            milhões de metros quadrados de urbanização.
            <br />
            Acreditamos que o solo que nos abriga é, de fato, nosso e deve ser
            tratado como tal. É por isso que mantemos um laboratório próprio,
            dedicado à análise de pavimentos e solos, garantindo a aplicação das
            melhores técnicas construtivas.
            <br />
            Vemos as pessoas pela perspectiva da cumplicidade, do trabalho em
            equipe, do enriquecimento pessoal, das preocupações coletivas e das
            motivações que as tornam únicas e nos permitem crescer como um todo.
            <br />
            Mantemos uma trajetória de crescimento, evolução e aprendizado
            constantes, em que nossos valores passam de geração para geração por
            intermédio de nossas obras.
            <br />
            Para nós, o urbanismo acessível e de qualidade é uma das formas mais
            poderosas de construir a vida com solidez, onde cada espaço traz uma
            história a ser lembrada.
            <br />
            <br />
            Alínea Urbanismo - Construindo espaços que fazem história.
          </p>
          <button
            onClick={() => setManifestOpen(true)}
            className={cn(st.viewMore, manifestOpen && st.hidden)}
          ></button>
        </div>
      </header>

      <section className={st.numbers}>
        <div className="container">
          <h3>Informações e números</h3>
          <ul className={st.circles}>
            {[numbers[7], numbers[0], numbers[8]].map(
              ({ name, number }, index) => (
                <li key={index}>
                  <span className={numberIcon(index)}></span>
                  <strong>{number}</strong>
                  {name}
                </li>
              )
            )}
          </ul>
        </div>
      </section>

      <section className={st.missionValues}>
        <div className={cn('container', st.table)}>
          <div>
            <div className={st.family}></div>
          </div>
          <div>
            <h3>Valores</h3>
            <img src={Logo} width="122" />
            <p>
              Acreditamos em relações de longo prazo;
              <br />
              <br />
              Honrar compromissos;
              <br />
              <br />
              Manter um ambiente produtivo baseado no profissionalismo e no
              comprometimento;
              <br />
              <br />
              Compartilhar resultados e conquistas;
              <br />
              <br />
              Antecipar movimentos do mercado;
              <br />
              <br />
              Comprometimento com a boa gestão financeira.
            </p>
          </div>
        </div>
        <div className={cn('container', st.bar)}>
          <div>
            <p>
              <strong>Prêmio Max Hablitzel</strong>
              <br />
              Destinado a empresas com destaque na preservação do meio ambiente
              em Santa Catarina.
            </p>
          </div>
          <div>
            <p>
              <strong>Laboratório de Solos e Pavimentação próprio</strong>
              <br />
              Para a fabricação de pavimentos, teste de qualidade de solos e
              bases construtivas
            </p>
          </div>
          <div>
            <p>
              <strong>Jardim Botânico</strong>
              <br />
              Implantação do primeiro jardim botânico de Santa Catarina,
              localizado em São José.
            </p>
          </div>
        </div>
      </section>

      <section className={st.whoWeAre}>
        <div className="container">
          <h3>Quem somos</h3>
          <div className={st.videoWrapper}>
            <div className={st.video}>
              <iframe
                src="https://www.youtube.com/embed/g4_2J992SfA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <h3>Websérie Alínea Urbanismo</h3>
          <p>Saiba mais sobre a Alínea Urbanismo e seus produtos</p>
          <Carousel className={st.webseries}>
            {campaigns.map(({ name, url }, key) => (
              <div
                className={st.slide}
                key={key}
                onClick={() => setVideoUrl(url)}
              >
                <LazyLoad
                  className={st.thumbnail}
                  src={`https://img.youtube.com/vi/${getVideoId(url)}/0.jpg`}
                />
                <h4>{name}</h4>
              </div>
            ))}
          </Carousel>
        </div>
      </section>

      {/*<section className={st.cities}>
        <div className="container">
          <h3>Presente nas cidades</h3>

          <ul className={st.list}>
            {attendants.map(({ name, items }, key) => (
              <li key={key}>
                <h4>{name}</h4>
                <ul>
                  {items.map((city, key) => (
                    <li key={key}>{city}</li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </section>*/}

      <Modal
        open={Boolean(videoUrl)}
        onClose={() => setVideoUrl(null)}
        className="video-modal"
      >
        <iframe
          width="560"
          height="315"
          src={
            videoUrl && `https://www.youtube.com/embed/${getVideoId(videoUrl)}`
          }
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>

      <Footer />
    </>
  )
}

export default Company
