// extracted by mini-css-extract-plugin
export var bar = "Company-module--bar--EBRgh";
export var circles = "Company-module--circles--fB80M";
export var cities = "Company-module--cities--lZvwU";
export var family = "Company-module--family--14f1h";
export var header = "Company-module--header--yz+uC";
export var hidden = "Company-module--hidden--7SfKx";
export var list = "Company-module--list--5+D24";
export var missionValues = "Company-module--mission-values--J8Nhq";
export var numbers = "Company-module--numbers--e9ibw";
export var open = "Company-module--open--KVUTO";
export var rotate = "Company-module--rotate--TVlya";
export var slide = "Company-module--slide--Tl1Ex";
export var table = "Company-module--table--xX+ZU";
export var thumbnail = "Company-module--thumbnail--nfUM6";
export var video = "Company-module--video--7PIk-";
export var videoWrapper = "Company-module--video-wrapper---n--Z";
export var viewMore = "Company-module--view-more--sW6E0";
export var webseries = "Company-module--webseries--0Necd";
export var whoWeAre = "Company-module--who-we-are--KXco2";